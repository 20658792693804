import { useRouter } from 'next/router'
import classnames from 'classnames'
import { analytics } from '@helpers/analytics'
import { getAnalyticsCountryCode } from '@helpers/env'
import { EmptyObject } from 'types/helpers'
import { ButtonLink } from '@components/ui/Button'
import MediaItem from '@components/ui/Medias/MediaItem'
import { ButtonLinkProps } from '@components/ui/Button/ButtonLink'
import styles from './styles.module.scss'
import { Asset, File } from 'types/contentful/restApi'

export type BenefitsItemProps = {
  index: number
  backgroundColor?: string
  imgFirst: boolean
  title: string
  button:
    | {
        event: Record<string, string>
        uri: string
        buttonStyle: ButtonLinkProps['variant']
        text: string
      }
    | EmptyObject
  medias: {
    [key: string]: Asset
  }
  description: string
  poster?:
    | {
        file: File
      }
    | EmptyObject
  showIndex?: boolean
  style?: string
  firstTimeUser?: boolean
  rootPath?: boolean
}

function BenefitsItem({
  index = 1,
  backgroundColor,
  imgFirst,
  title,
  button = {},
  medias,
  description,
  poster = {},
  showIndex = false,
  style = '',
  firstTimeUser = false,
  rootPath = false
}: BenefitsItemProps) {
  const { route } = useRouter()

  const sendEvent = () => {
    if (button.event) {
      const type = rootPath ? { type: firstTimeUser ? 'New' : 'Existing' } : {}
      analytics('Click', {
        ...button.event,
        store_location: getAnalyticsCountryCode(),
        ...type
      })
    }
  }

  const mediaAsset = !!medias && medias['0']
  const { title: assetTitle, file: assetFile } = mediaAsset
  const contentType = assetFile?.contentType || ''
  const isMarkupAsset = contentType.includes('html')

  const sectionClasses = classnames(styles.contentBenefits, {
    [styles.contentBenefits__markup]: isMarkupAsset
  })

  const contentClasses = classnames(styles.contentBenefits__containerDetails, {
    [styles.contentBenefits__containerDetailsLeft]: !imgFirst
  })

  const mediaClasses = classnames(styles.contentBenefits__media, {
    [styles.contentBenefits__media_reverseOrder]: !imgFirst
  })

  return (
    <section className={sectionClasses} style={{ backgroundColor }}>
      {mediaAsset && (
        <div className={mediaClasses}>
          <MediaItem
            key={`${assetTitle.toString()}`}
            poster={{ ...poster.file }}
            {...mediaAsset}
          />
        </div>
      )}
      <div className={contentClasses}>
        <div className={`${styles.contentBenefits__content} ${style}`}>
          <h3 className={styles.contentBenefits__title}>
            {showIndex && <span>{index}</span>}
            {title}
          </h3>
          <div className={styles.contentBenefits__byline}>{description}</div>
          {button.uri && /^\/pages$/.test(route) && (
            <ButtonLink
              variant={button.buttonStyle || 'primary'}
              classes={styles.contentBenefits__button}
              href={button.uri}
              onClick={sendEvent}
              fullWidth="inherit"
            >
              {button.text}
            </ButtonLink>
          )}
        </div>
      </div>
    </section>
  )
}

export default BenefitsItem
