import BenefitsItem, {
  BenefitsItemProps
} from '@components/ui/Benefits/BenefitsItem'
import styles from './styles.module.scss'
import { objectKeys } from '@helpers/utils'

type BenefitsProps = {
  backgroundColor?: string
  benefits: {
    [key: string]: BenefitsItemProps
  }
  firstTimeUser?: boolean
}

function Benefits({
  backgroundColor = 'white',
  benefits,
  firstTimeUser = false
}: BenefitsProps) {
  const themeColor = {
    backgroundColor: backgroundColor === 'grey' ? '#f7f8f8' : backgroundColor
  }
  return (
    <section className={`${styles.benefits} benefits`} style={themeColor}>
      <div className={styles.benefits__content}>
        {objectKeys(benefits).map(i => (
          <BenefitsItem
            {...benefits[i]}
            index={Number(i) + 1}
            key={i.toString()}
            firstTimeUser={firstTimeUser}
          />
        ))}
      </div>
    </section>
  )
}

export default Benefits
