/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import styles from './styles.module.scss'

const customLoader = ({ src }: { src: string }) => {
  return src
}

function Image({ loading = 'lazy', src, ...props }: NextImageProps) {
  const [isReady, setIsReady] = useState(false)
  return (
    <div
      className={`${styles.ImageWrapper} ${isReady ? styles.Image_ready : ''}`}
    >
      <NextImage
        src={src}
        unoptimized
        loader={customLoader}
        onLoadingComplete={() => {
          setIsReady(true)
        }}
        loading={loading}
        layout="responsive"
        {...props}
      />
    </div>
  )
}

export default Image
